import React ,{Component}from "react"
import { Link } from "gatsby"

export class Sidebar extends Component {    
    render() {   
      const {blog} = this.props   
    
      return (
        <div>
          <span>
            <Link key={blog.slug} to={blog.slug} >
              <img src={blog.urlToImage}  alt={blog.image} className="img-rounded" />           
            </Link>
          
            <Link key={blog.slug} to={`/blog/${blog.slug}`}>
              <span>{blog.title}</span>
            </Link> 

            <br />
            <span className="meta">{blog.publishedAt}</span>
          </span> 
        </div>
      )
    }
  }
  
  export default Sidebar
  





  
  
  
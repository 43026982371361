import React, { Component } from "react"
import Layout from "../components/layout"
import Sidebar from "../components/sidebar"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
// import Img from "gatsby-image"
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
// import Slider from "react-slick";
import { DiscussionEmbed } from "disqus-react";
import Share from '../components/Share';

class BlogPostTemplate extends Component {
  render() {
    const singlepost = this.props.data.newsApiTopHeadlines
    const siteurl = this.props.data.site.siteMetadata.url;
    const twiteerhandle = this.props.data.site.siteMetadata.twitterHandle
    // const Imageurl =this.props.data.contentfulPortfolio.galleryImage
    const post = this.props.data.allNewsApiTopHeadlines.nodes
    const disqusShortname = "myclicks-1";
    const disqusConfig = {
      identifier: singlepost.id,
      title: singlepost.title,
    };

    const socialConfigss = {   
      site: {
        siteMetadata: { siteurl, twiteerhandle },
      } ,
      title: singlepost.title,
      slug: singlepost.slug
    };

    let SliderImage;
    // const settings = { dots: true, infinite: true, speed: 500, slidesToShow: 1, slidesToScroll: 1 };
   
    // if (!Imageurl) SliderImage = <Img fluid={singlepost.blogImage.fluid} backgroundColor={"#f4f8fb"} /> 
    // else{
    //   SliderImage = <Slider {...settings}>                 
    //     {Imageurl.map(({ file } , index) => {
    //       return (
    //         <div>
    //           <img src={file.url} alt="modhera-sun-temple"/>
    //         </div>
    //       )
    //     })}
    //   </Slider>
    // }

    SliderImage = <img src={singlepost.urlToImage} alt={singlepost.title} />

    return (
      <Layout>
        <Helmet title={`${singlepost.blogTitle}`} />

        <div className="inner-blog-post pad-40">
          <div className="container">
            <div className="row">
           
              <div className="col-lg-7 col-md-7">
                <div className="entry-media">
                  {SliderImage}
                </div>                   
                <div className="post-content">
                  <h2 className="section-headline"> {singlepost.blogTitle} </h2>   
                  {/* <p dangerouslySetInnerHTML={{ __html: singlepost.content }} /> */}
                  <p>{singlepost.content }</p>
                  <p>
                    <a href={singlepost.url} target="_blank">Você quer saber mais</a>
                  </p>
                </div>     
              </div>
              <div className="col-md-4 offset-md-1 ">
                <div className="sidebar-blk">
                    <ul className="">

                      {post.map((node, index) => {
                        return (
                          <li key={node.id} id={index + '1'}>
                            {/* <BlogPreview blog={node} /> */}
                            <Sidebar blog={node} />
                          </li>
                        )
                      })}

                  </ul>
                </div>           
              </div>  
            </div>

            <Share socialConfig={{...socialConfigss.site.siteMetadata.twiteerhandletitle, config: { url: `${siteurl}${socialConfigss.slug}`, title:`${socialConfigss.title}`, }, }}  />
            <DiscussionEmbed shortname={disqusShortname} config={disqusConfig} />
          </div>        
        </div>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query blogPostQuery($slug: String) {
    site {
      siteMetadata {
        url
        twitterHandle
      }
    }
    
    allNewsApiTopHeadlines(filter: {urlToImage: {nin: "null"}}, limit: 5, sort: {order: ASC, fields: publishedAt}) {
      totalCount
      nodes {
        author
        description
        id
        title
        urlToImage
        publishedAt(fromNow: true)
      }
    }
    
    newsApiTopHeadlines(slug: {eq: $slug}) {
      author
      content
      description
      slug
      title
      url
      urlToImage
      publishedAt(fromNow: true)
    }
   
  }
`
